export interface Hook<Data, Result> {
  /**
   * Subscribes to the hook.
   * @returns A function to unsubscribe.
   *
   * @example
   * ```
   * const unsubscribe = hook.tap(() => {});
   * unsubscribe();
   * ```
   */
  tap(callback: (data: Data) => Result): () => void;

  /**
   * Subscribes to the hook once.
   * @returns A function to unsubscribe.
   *
   * @example
   * ```
   * const unsubscribe = hook.tapOnce(() => {});
   * unsubscribe();
   * ```
   */
  tapOnce(callback: (data: Data) => Result): () => void;

  /**
   * Unsubscribes from the hook.
   * @param callback The callback to unsubscribe (should have same reference as the one passed to `tap`).
   *
   * @example
   * ```
   * const callback = () => {};
   * hook.tap(callback);
   * hook.untap(callback);
   * ```
   * Alternatively, you can use the function returned from `tap`:
   * @example
   * ```
   * const unsubscribe = hook.tap(() => {});
   * unsubscribe();
   * ```
   */
  untap(callback: (data: Data) => Result): void;

  /**
   * Triggers the hook.
   * Calls all subscribed callbacks.
   */
  fire(data: Data): Result[];
}

export const createHook = <Data = void, Result = void>(): Hook<
  Data,
  Result
> => {
  let taps: Array<(data: Data) => Result> = [];

  const tap: Hook<Data, Result>['tap'] = (callback) => {
    taps.push(callback);
    return function unsibscribe() {
      untap(callback);
    };
  };

  const tapOnce: Hook<Data, Result>['tapOnce'] = (callback) => {
    const wrappedCb = (data: Data) => {
      untap(wrappedCb);
      return callback(data);
    };
    taps.push(wrappedCb);
    return function unsubscribe() {
      untap(wrappedCb);
    };
  };

  const untap: Hook<Data, Result>['untap'] = (callback) => {
    taps = taps.filter((c) => c !== callback);
  };

  const fire: Hook<Data, Result>['fire'] = (data) =>
    taps.map((tapCallback) => tapCallback(data));

  return {
    tap,
    tapOnce,
    untap,
    fire,
  };
};

export interface InterceptorHookResult<Data> {
  data: Data;
  isDataUpdated?: boolean;
  isCanceled?: boolean;
}

export interface InterceptorHookInterceptor<Data> {
  (
    data: Data,
    options?: {
      cancel?: () => void;
      update?: (dataUpdated: Partial<Data>) => void;
    },
  ): Data | void;
}

export interface InterceptorHook<Data> {
  tap(callback: InterceptorHookInterceptor<Data>): void;
  intercept(data: Data): InterceptorHookResult<Data>;
}

/**
 * Creates an interceptor hook.
 */
export const createInterceptorHook = <Data>(options?: {
  isCancelAllowed?: boolean;
  isUpdateDataAllowed?: boolean;
}): InterceptorHook<Data> => {
  const taps: Array<InterceptorHookInterceptor<Data>> = [];

  const tap: InterceptorHook<Data>['tap'] = (interceptor) => {
    taps.push(interceptor);
  };

  const intercept: InterceptorHook<Data>['intercept'] = (inputData) =>
    taps.reduce<InterceptorHookResult<Data>>(
      (output, tapInterceptor) => {
        if (output.isCanceled) {
          return output;
        }

        let dataUpdated: Data = null;
        let isDataUpdated = false;
        let isCanceled = false;
        const dataUpdatedByTapReturn = tapInterceptor(output.data, {
          update: (dataUpdatedPartial) => {
            if (!options?.isUpdateDataAllowed) {
              throw new Error(
                'Interceptor Hook is not updatable.\nCheck the `isUpdateDataAllowed` option.',
              );
            }

            dataUpdated = { ...output.data, ...dataUpdatedPartial };
            isDataUpdated = true;
          },
          cancel: () => {
            if (!options?.isCancelAllowed) {
              throw new Error(
                'Interceptor Hook is not cancelable.\nCheck the `isCancelable` option.',
              );
            }
            isCanceled = true;
          },
        });

        if (dataUpdatedByTapReturn && dataUpdatedByTapReturn !== output.data) {
          if (isDataUpdated) {
            throw new Error(
              'Interceptor Hook: `update` data and `return` updated data are mutually exclusive.',
            );
          }
          dataUpdated = dataUpdatedByTapReturn;
        }

        return {
          data: dataUpdated ?? output.data,
          isDataUpdated,
          isCanceled,
        };
      },
      {
        data: inputData,
      },
    );

  return {
    tap,
    intercept,
  };
};

export interface PromiseHook<Data = void> {
  promise: Promise<Data>;
  resolve: (value: Data) => void;
  reject: (reason: any) => void;
}

export function createPromiseHook<Data = void>(): PromiseHook<Data> {
  let _resolve: PromiseHook<Data>['resolve'];
  let _reject: PromiseHook<Data>['reject'];

  const promise = new Promise<Data>((resolve, reject) => {
    _resolve = resolve as any;
    _reject = reject;
  });
  return {
    resolve: _resolve,
    reject: _reject,
    promise,
  };
}
